import gql from 'graphql-tag';

export const GET_TOURNAMENT_DIRECTORS = gql`
  query GetTournamentDirectors(
    $orgId: UUID!
    $limit: Int!
    $offset: Int!
    $sorts: [PropertySortInput!]
    $filters: [PropertyFilterInput!]
  ) {
    paginatedOrganisationContactRelationships(
      filter: { organisationId: $orgId }
      paginationOptions: { limit: $limit, offset: $offset, filters: $filters, sorts: $sorts }
    ) {
      totalItems
      items {
        id
        organisationId
        contact {
          id
          safePlay {
            status
          }
          userId
          firstName
          lastName
          emailAddress
          isRegistered
        }
        invitation {
          expiryDate
          createdAt
        }
        type
        createdAt
      }
    }
  }
`;

export const clearOCRs = (cache) => {
  Object.keys(cache.data.data).forEach(
    (key) => key.match(/paginatedOrganisationContactRelationships/) && cache.data.delete(key),
  );
};
