import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import InviteDirector from 'src/components/tournament-director-invite/tournament-director-invite';

const TournamentDirectorInvite = () => {
  return (
    <Layout>
      <SEO title="Invite Tournament Director" />
      <PrivateRoute>
        <InviteDirector />
      </PrivateRoute>
    </Layout>
  );
};

export default TournamentDirectorInvite;
