import React, { useCallback, useEffect, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import InvitePerson from 'src/components/invite-person/invite-person';
import PageHeader from 'src/components/page-header/page-header';
import { clearOCRs } from 'src/components/tournament-directors/tournament-directors-queries';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getClientConfig } from 'src/config/config';
import { AddTournamentDirector, AddTournamentDirectorVariables } from 'src/graphql-types/AddTournamentDirector';
import { NavigationState } from 'src/utils/typedefs/navigation';

import { INVITE_TOURNAMENT_DIRECTOR } from './tournament-director-invite-queries';

interface Contact {
  externalId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
}

interface AddNewContactProps {
  variant: 'id-or-email' | 'name-and-email';
}

const InviteDirector: React.FC = () => {
  const { t } = useTranslation();
  const nameAndEmail = getClientConfig().inviteStaffViaEmailAndName;
  return (
    <PageMaxWidth>
      <PageHeader title={t('invite director')} />
      <AddNewContact variant={nameAndEmail ? 'name-and-email' : 'id-or-email'} />
    </PageMaxWidth>
  );
};

const AddNewContact: React.FC<AddNewContactProps> = ({ variant }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const [inviteDirector, { loading, data, error }] = useMutation<AddTournamentDirector, AddTournamentDirectorVariables>(
    INVITE_TOURNAMENT_DIRECTOR,
    { client: tournamentsClient },
  );

  const errorMessage = useMemo(() => {
    if (error?.graphQLErrors && error.graphQLErrors.length === 1) {
      const e = error.graphQLErrors[0];
      if (e.message === 'userNotFound') return t('user not found');
      if (e.message === 'alreadyDirectorAtOrganisation') return t('user already director');
    }
    return error?.message;
  }, [error, t]);

  useEffect(() => {
    if (data?.inviteTournamentDirectorFromExternalId) {
      const state: NavigationState = {
        popup: {
          message: t('director invite success'),
          link: `/tournaments/directors/${data.inviteTournamentDirectorFromExternalId.id}`,
        },
      };
      navigate('/tournaments/directors/', { state });
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: Contact) => {
      if (!orgId) return;
      const { firstName, lastName, emailAddress, externalId } = values ?? {};
      const contactFields = (() => {
        if (variant === 'id-or-email') return { externalId: externalId?.trim() };
        if (variant === 'name-and-email')
          return {
            emailAddress: emailAddress?.trim(),
            firstName: firstName?.trim(),
            lastName: lastName?.trim(),
          };
      })();
      const contact = { ...contactFields, organisationId: orgId };
      inviteDirector({ variables: { contact }, update: clearOCRs });
    },
    [orgId, inviteDirector, variant],
  );

  return <InvitePerson variant={variant} errorMessage={errorMessage} loading={loading} handleSubmit={onSubmit} />;
};

export default InviteDirector;
